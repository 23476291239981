// src/features/auth/authSlice.ts
import {createSlice, Dispatch} from '@reduxjs/toolkit';
import axios from "axios";
import toast from "react-hot-toast";

type initialState = {
    loading: boolean,
    error: boolean,
    items: [],
};

export const initialState = {
    loading: false,
    error: false,
    items: [],
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setItems: (state, {payload}) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
        },
        setError: (state) => {
            state.error = true;
        }
    }
});

export const {setLoading, setItems, setError} = userSlice.actions;

export const userSelector = (state: { items: any; }) => state.items;

export default userSlice.reducer;

const token = typeof window !== 'undefined' && localStorage.getItem('access_token');
// console.log('this is the token: ***** ',token);

const api = axios.create({
    baseURL: process.env.API_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export function fetchUser(userId: string) {
    return async (dispatch: Dispatch) => {
        await api
            .post('/api/auth/' + userId)
            .then((response) => {
                dispatch(setItems(response.data));
            })
            .catch((error) => {
                dispatch(setError());
            })
    }
}

export const getUser = async (userId: string) => {
    try {
        const response = await api.post('/api/auth/' + userId)

        return response.data
    } catch (event) {
        const error = event as Error
        console.error(error)
    }
};

export const createUser = async (data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string
    img: string
    externalId: string
}, isErrorShow: boolean) => {
    try {
        const response = await api.post('/api/users/default-user', data)

        return response.data
    } catch (event) {
        const error = event as Error
        console.error(error)

        if (isErrorShow) return;

        toast.error("Create user failed,\nSystem message: " + error.message);
    }
};

export const createUserOld = async (data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string
    img: string
    externalId: string
}) => {
    return async (dispatch: Dispatch) => {
        await api
            .post('/api/users/default-user', data)
            .then((response) => {
                dispatch(setItems(response.data));
            })
            .catch((error) => {
                dispatch(setError());
            })
    }
}

export function clearUser() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}
