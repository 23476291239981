// src/features/auth/authSlice.ts
import {createSlice, Dispatch} from '@reduxjs/toolkit';
import axios from "axios";
import toast from "react-hot-toast";

type initialState = {
    loading: boolean,
    error: boolean,
    message: string,
    items: [],
};

export const initialState = {
    loading: false,
    error: false,
    message: '',
    items: [],
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.message = '';
        },
        setItems: (state, {payload}) => {
            state.loading = false;
            state.error = false;
            state.items = payload;
            state.message = '';
        },
        setError: (state, {payload}) => {
            state.error = true;
            state.message = payload;
        },
        negateError: (state) => {
            state.error = false;
            state.message = '';
        }
    }
});

export const {setLoading, setItems, setError, negateError} = loginSlice.actions;

export const loginSelector = (state: { items: any; }) => state.items;

export default loginSlice.reducer;

const token = typeof window !== 'undefined' && localStorage.getItem('access_token');

const api = axios.create({
    baseURL: process.env.API_URL,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export const updateUser = async (userId: string, data: any) => {
    let token = localStorage.getItem("access_token");
    if (!token) return;

    const response = await api.patch(process.env.API_URL + "/api/users/profile/" + userId, {...data, phone: data.mobilePhone}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })

    if (response.data.status) {
        toast.success("Successfully updated");
        return response.data
    } else {
        toast.error("Something went wrong, try again");
    }
};

export function fetchLogin(userId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(negateError())

        await api
            .post('/api/auth/' + userId)
            .then((response) => {
                console.log("fetchLogin response", response);
                // console.log('the response: ***** ',response.data.access_token);
                dispatch(setItems(response.data));

                typeof window !== 'undefined' && localStorage.setItem('access_token', response.data.access_token);

                return response.data
            })
            .catch((error) => {
                toast.error(error.response?.data?.message);
                dispatch(setError(error.response.data.message));
            })
    }
}

export function fetchLoginCart(userId: string) {
    return async (dispatch: Dispatch) => {
        let element: any = [];
        let firstElement: any;
        dispatch(negateError())

        await api.post('/api/auth/' + userId)
            .then(async (response) => {
                firstElement = response.data;

                const apiToken = axios.create({
                    baseURL: process.env.API_URL,
                    withCredentials: false,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + firstElement.access_token
                    }
                });

                let currentCart = await apiToken.get('/api/shopping-car/current');

                if (!currentCart.data.status) {
                    let element: boolean = false;

                    let data = JSON.stringify({
                        "courses": []
                    });

                    var config = {
                        method: 'post',
                        url: process.env.API_URL + '/api/shopping-car',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + firstElement.access_token
                        },
                        data: data
                    };

                    const newCart = await axios(config);

                    currentCart = await apiToken.get('/api/shopping-car/current');
                    const newCart2 = await axios(config);
                }

                element = [firstElement, currentCart.data];
            })
            .catch(error => {
                toast.error(error.response?.data?.message);
                console.log(error.response);
                firstElement = error.response.data.message;
                dispatch(setError(error.response.data.message));
            })

        // // dispatch(setItems(userInfo.data));


        // if(!currentCart.data.status) {
        //     let element: boolean = false;

        //     let data = JSON.stringify({
        //         "courses": []
        //     });

        //     var config = {
        //         method: 'post',
        //         url: process.env.API_URL + '/api/shopping-car',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Bearer ' + userInfo.data.access_token
        //         },
        //         data: data
        //     };

        //     const newCart = await axios(config);

        //     console.log('the new cart: **** ',newCart);

        //     currentCart = await apiToken.get('/api/shopping-car/current');

        // return element;
        // }


        return element;
    }
}

export function fillLogin(loginInfo: any) {
    return async (dispatch: Dispatch) => {
        dispatch(setItems(loginInfo));
    }
}

export function logOut() {
    return async (dispatch: Dispatch) => {
        dispatch(setItems([]));
    }
}
